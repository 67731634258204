import {
  createEffect,
  createResource,
  For,
  Match,
  onMount,
  Show,
  Suspense,
  Switch,
} from "solid-js";
import SolidMarkdown from "solid-markdown";
import { getBrand, getBrandExtraMetadata } from "~/server/apis/client_apis";
import { getBrandTncData } from "~/server/data/brand_tnc_data";
import { BrandExtraMetadata, VoucherProduct } from "~/server/types/brand";
import { DottedLoader } from "~/widgets/loader";
import { AboutThisVoucher } from "../brand_l2/about_this_card";

type BrandDenominationDetailModalProps = {
  brandKey: string;
};
type ProcurementBrandDetailModalRouteData = {
  voucherDetail: VoucherProduct;
  tnc: string;
  extraMetadata: BrandExtraMetadata;
};

export function BrandDenominationDetailModal(
  props: BrandDenominationDetailModalProps
) {
  const [routeData] = createResource<ProcurementBrandDetailModalRouteData>(
    async () => {
      const voucherDetail = await getBrand(props.brandKey);
      const tnc = await getBrandTncData(
        voucherDetail.voucherProductMetadata.tncResId
      );
      const extraMetadata = await getBrandExtraMetadata(props.brandKey);
      return { voucherDetail, tnc, extraMetadata };
    }
  );

  return (
    <Switch>
      <Match when={routeData.state === "ready"}>
        <div class="m-2 flex w-full flex-col gap-4">
          <p class="text-h4 text-textDark">
            {routeData()?.voucherDetail?.name} gift card at{" "}
            <span class="text-successDark">
              {routeData()?.voucherDetail.restrictions.rewardCap.value}% off
            </span>
          </p>
          <img
            src={
              routeData()?.voucherDetail?.voucherProductMetadata?.heroImageUrl
            }
            alt={`${routeData()?.voucherDetail?.voucherProductMetadata.title}`}
            class=" h-[280px] w-[480px] rounded-xl"
          />
          <Show
            when={
              routeData()?.extraMetadata.voucherDescription &&
              routeData()?.extraMetadata.voucherHighlights
            }
          >
            <div class="flex ">
              <AboutThisVoucher
                voucherDescription={
                  routeData()?.extraMetadata.voucherDescription!
                }
                voucherHighlights={
                  routeData()?.extraMetadata.voucherHighlights!
                }
              />
            </div>
          </Show>

          <Show
            when={
              routeData()?.voucherDetail?.voucherProductMetadata
                .howItWorksSteps &&
              routeData()?.voucherDetail?.voucherProductMetadata.howItWorksSteps
                .length! > 0
            }
          >
            <hr class="h-px w-full border border-baseTertiaryDark" />
            <div class="flex flex-col gap-4">
              <p class="text-bold text-textDark">How to redeem</p>
              <For
                each={
                  routeData()?.voucherDetail?.voucherProductMetadata
                    .howItWorksSteps
                }
              >
                {(steps, index) => {
                  return (
                    <div class="flex items-start justify-start gap-2 text-medium">
                      <div class="flex h-[18px] w-[18px] flex-shrink-0 items-center justify-center rounded-full bg-baseTertiaryMedium  text-[10px] font-semibold text-textDark">
                        {index() + 1}
                      </div>
                      {steps.description}
                    </div>
                  );
                }}
              </For>
            </div>
          </Show>
          <Show when={routeData()?.tnc != ""}>
            <hr class="h-px w-full border border-baseTertiaryDark" />
            <div class="flex flex-col gap-4">
              <p class="text-bold text-textDark">Things to note</p>

              <For each={routeData()?.tnc.split("\n")}>
                {(tnc, index) => {
                  return (
                    <div class="flex items-start justify-start gap-2 text-medium">
                      <div class="flex h-[18px] w-[18px] flex-shrink-0 items-center justify-center rounded-full bg-baseTertiaryMedium  text-[10px] font-semibold text-textDark">
                        {index() + 1}
                      </div>
                      <div class="w-fit">
                        <SolidMarkdown>{tnc.replace("- ", "")}</SolidMarkdown>
                      </div>
                    </div>
                  );
                }}
              </For>
            </div>
          </Show>
        </div>
      </Match>
      <Match when={true}>
        <div class="flex-1 items-center justify-center">
          <DottedLoader color="#999" />
        </div>
      </Match>
    </Switch>
  );
}
