"use server";

import { getProcurementOrderDetail } from "../apis/server_apis";
import { ProcurementOrderDetailResponse } from "../types/order";

export type ProcurementOrderDetailRouteData = {
  details: ProcurementOrderDetailResponse;
};

export type ProcurementOrderDetailProps = {
  orderId: string;
};

export const getProcurementOrderDetailRouteData = async (
  orderId: string
): Promise<ProcurementOrderDetailRouteData> => {
  let details = await getProcurementOrderDetail({ orderId: orderId });
  return { details };
};
