import {
  cache,
  createAsync,
  useLocation,
  useNavigate,
  useParams,
} from "@solidjs/router";
import { Accessor, createSignal, onMount, Show, Suspense } from "solid-js";
import { infoIcon, successTick } from "~/assets/assets";
import { useModal } from "~/components/modal";
import { BrandDenominationDetailModal } from "~/components/procurement/brand_detail_modal";
import { downloadProcurementOrder } from "~/server/apis/client_apis";
import {
  getProcurementOrderDetailRouteData,
  ProcurementOrderDetailRouteData,
} from "~/server/data/procurement_order_detail_route_data";
import { goBack, goBackNTimes, popUntilHome } from "~/shared_states/modal";
import { toIndianNumber } from "~/utils/number";
import { ThreeDotLoader } from "~/widgets/button";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";
import { DottedLoader } from "~/widgets/loader";
import { Spacer } from "~/widgets/spacer";

const getProcurementOrderDetailRouteData$C = cache(
  getProcurementOrderDetailRouteData,
  "procurement_orderDetail"
);

type pageData = {
  fromPurchasesPage: Boolean;
};

export default function BulkOrderId() {
  const params = useParams();
  const routeData: Accessor<ProcurementOrderDetailRouteData | undefined> =
    createAsync<ProcurementOrderDetailRouteData>(() => {
      return getProcurementOrderDetailRouteData$C(params.orderId);
    });
  const navigator = useNavigate();
  const { openModal } = useModal()!;
  const location = useLocation();
  const pageData = location.state as pageData;

  onMount(() => {
    if (routeData()?.details.status === "FAILED") {
      navigator("/order/" + routeData()?.details.id, { replace: true });
    }
  });

  const [loading, setLoading] = createSignal(false);

  const downloadOrder = async (orderId: string) => {
    setLoading(true);
    try {
      const response = await downloadProcurementOrder(orderId);
      if (response) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `hubble_procurement_order_${orderId}.xlsx`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download the order:");
      }
    } catch (error) {
      console.error("An error occurred while downloading the order:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Suspense
      fallback={
        <div class="flex w-full items-center justify-center">
          <DottedLoader color="#999" />
        </div>
      }
    >
      <div class="w-full  md:mx-auto md:max-w-[1136px]">
        <Spacer height={60} />
        <div class="flex w-full flex-col items-end justify-end  ">
          <div
            class=" flex h-9 w-9 cursor-pointer items-center justify-center rounded-[100%] border bg-white"
            style={{ "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.02)" }}
            onClick={() => {
              if (pageData.fromPurchasesPage) {
                navigator(-1);
              } else {
                navigator("/catalogue", { replace: true });
              }
            }}
          >
            <PhosphorIcon name="x" fontSize={20} class="text-baseTertiary" />
          </div>
        </div>
        <div class="flex flex-col items-center justify-center ">
          <div class="mb-2 h-20 w-20">
            <HubbleImage src={successTick} alt="" class="" />
          </div>

          <div class="pb-1 text-f12Bold text-successDark">
            Payment successful
          </div>
          <div class="pb-1 text-h3 text-textDark">
            Gift cards has been generated
          </div>
          <div class="text-f12 text-textNormal">
            {" "}
            {new Date(
              routeData()?.details.transactedAt ?? ""
            ).toLocaleDateString("en-IN", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })}{" "}
            | ID: {routeData()?.details.id}
          </div>
          <Show when={routeData()?.details.notes}>
            <div class="mt-4 text-medium text-textDark">
              {routeData()?.details.notes}
            </div>
          </Show>
        </div>
        <div class="pt-6">
          <div
            class="flex w-full flex-col rounded-[20px] bg-[#F5F6F9] p-4"
            style={{ border: "2px solid rgba(255, 255, 255, 1)" }}
          >
            <div class="flex justify-between">
              <div class="flex flex-col justify-start">
                <div class="text-mediumBold text-darkBg">Your gift cards</div>
                <div class="flex  text-medium text-textDark">
                  <div>
                    {toIndianNumber(routeData()?.details.brandsCount ?? 0)}{" "}
                    brands
                  </div>
                  <div class="mx-1 h-4 w-[1px] bg-baseTertiaryDark"></div>
                  <div>
                    {toIndianNumber(routeData()?.details.voucherCount ?? 0)}{" "}
                    cards
                  </div>
                  <div class="mx-1 h-4 w-[1px] bg-baseTertiaryDark"></div>
                  <div>
                    ₹{toIndianNumber(routeData()?.details.totalAmount ?? 0)}{" "}
                    worth
                  </div>
                  <div class="mx-1 h-4 w-[1px] bg-baseTertiaryDark"></div>
                  <div>
                    ₹
                    {toIndianNumber(
                      routeData()?.details.totalAmount! -
                        routeData()?.details.totalDiscount!
                    )}{" "}
                    paid
                  </div>
                  <Show
                    when={
                      routeData()?.details.failedVoucherCount &&
                      routeData()?.details.failedVoucherCount! > 0
                    }
                  >
                    <div class="mx-1 h-4 w-[1px] bg-baseTertiaryDark"></div>
                    <div class="text-errorDark">
                      {toIndianNumber(
                        routeData()?.details.failedVoucherCount ?? 0
                      )}{" "}
                      cards failed
                    </div>
                  </Show>
                  <Show
                    when={
                      routeData()?.details.refundTotalAmount &&
                      routeData()?.details.refundTotalAmount! > 0
                    }
                  >
                    <div class="mx-1 h-4 w-[1px] bg-baseTertiaryDark"></div>
                    <div class="text-errorDark">
                      ₹
                      {toIndianNumber(
                        routeData()?.details.refundTotalAmount ?? 0
                      )}{" "}
                      refunded
                    </div>
                  </Show>
                </div>
              </div>
              <div
                class="flex w-40 cursor-pointer items-center justify-center rounded-[8px] bg-black px-[22px] py-[5px] text-mediumBold text-white "
                style={{
                  "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.02);",
                }}
                onClick={() => {
                  if (!loading()) {
                    downloadOrder(params.orderId);
                  }
                }}
              >
                <Show when={!loading()} fallback={<ThreeDotLoader />}>
                  <PhosphorIcon
                    name="cloud-arrow-down"
                    fontSize={20}
                    class="pr-[2px]"
                  />
                  {"Download CSV"}
                </Show>
              </div>
            </div>
            <Show
              when={
                routeData()?.details.status === "FULFILLED" ||
                routeData()?.details.status === "PARTIALLY_FULFILLED"
              }
            >
              <div class="pt-4">{PurchaseTable()}</div>
            </Show>
          </div>
        </div>
      </div>
    </Suspense>
  );

  function PurchaseTable() {
    const [activeDetailBoxId, setActiveDetailBoxId] = createSignal("");
    return (
      <div class="pb-10">
        <div class="shadow rounded-lg  bg-white">
          <table class="min-w-full divide-y divide-gray-200 rounded-lg">
            <thead class="sticky -top-0 z-10 rounded-t-lg bg-gray-50">
              <tr class="">
                <th class="rounded-tl-lg   border-l border-r border-t  p-3 text-left text-smallBold text-textDark">
                  Brand
                </th>
                <th class="border-r  border-t   px-3 text-left text-smallBold text-textDark">
                  AMOUNT
                </th>
                <th class="border-r  border-t   p-3 text-left text-smallBold text-textDark">
                  CARD NO.
                </th>
                <th class="border-r border-t  p-3 text-left text-smallBold text-textDark">
                  CARD PIN
                </th>
                <th class="border-r border-t   p-3 text-left text-smallBold text-textDark">
                  STATUS
                </th>
                <th class="rounded-tr-lg border-r border-t p-3 text-smallBold text-textDark">
                  DETAIL
                </th>
              </tr>
            </thead>
            <tbody class=" divide-y  divide-gray-200 rounded-full  border bg-white ">
              {routeData()?.details.vouchers.map((vouchers) => (
                <tr>
                  <td class="whitespace-nowrap border-r p-3 text-mediumBold text-textDark">
                    {
                      <div class="flex items-center justify-start gap-2">
                        <HubbleImage
                          src={vouchers.brandLogoUrl}
                          alt={vouchers.brandName}
                          class="h-8 w-8 rounded-full"
                        />
                        {vouchers.brandName}
                      </div>
                    }
                  </td>
                  <td class="whitespace-nowrap border-r p-3 text-medium text-textDark">
                    ₹{toIndianNumber(vouchers.amount)}
                  </td>
                  <td class="whitespace-nowrap border-r p-3 text-medium text-textDark">
                    {vouchers.cardNumber}
                  </td>
                  <td class="whitespace-nowrap border-r p-3 text-medium text-textDark">
                    {vouchers.cardPin}
                  </td>
                  <td
                    class={`whitespace-nowrap border-r p-3 text-medium  ${
                      vouchers.status == "FAILED"
                        ? "text-errorDark"
                        : "text-successDark"
                    }`}
                  >
                    {vouchers.status == "ACTIVE"
                      ? "SUCCESS"
                      : vouchers.status == "FAILED"
                        ? "Failed to generate"
                        : vouchers.status}
                  </td>
                  <td
                    class={`w-8 cursor-pointer items-center  ${activeDetailBoxId() == vouchers.id ? "bg-baseTertiaryMedium" : "bg-white"} `}
                    onMouseEnter={() => {
                      setActiveDetailBoxId(vouchers.id);
                    }}
                    onMouseLeave={() => {
                      setActiveDetailBoxId("");
                    }}
                    onClick={() => {
                      openModal(
                        () =>
                          BrandDenominationDetailModal({
                            brandKey: vouchers.brandKey,
                          }),
                        "md:w-[512px]"
                      );
                    }}
                  >
                    <div class="flex w-full justify-center">
                      <HubbleImage src={infoIcon} alt="" class="h-5 w-5" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
